// This is the homepage.

import React from "react"
//import { Link } from "gatsby"
import styled from "styled-components"
import Layout from "../components/layout"
import SEO from "../components/seo"



const Heading1 = styled.h1`
    font-size: 1.8em;
    padding: 10px;
    text-align: center;
    width: 100%;
    min-height: 85px;
    margin: auto;
    font-weight:600;
  
`


const Wrapper = styled.div`
    width: 100%;
    background-position: center;
    padding: 10px;
    padding: 40px 40px 40px 40px;
`

const ContentWrapper = styled.div`
    text-align: center;
    padding: 20px 10px 20px 10px;
    margin: auto;
`

const Content = styled.p`
    width: auto;
    font-size: 1.3em;
    line-height: 30px;
    text-align: center;
    padding: 20px 0px 20px 0px;
`


class BlogIndex extends React.Component {
  render() {

    return (

      <Layout location={this.props.location}>
        <SEO title="Timeline" />
         <Wrapper><Heading1>About</Heading1>
         <ContentWrapper>
         <Content>Fine Arabica coffee is our quality ingredient that we handle with utmost care - stringent quality control is our hallmark.</Content>

           <Content>Bailey Coffee hails from the second wave of serious espresso, founded Devonport, Auckland, New Zealand & now based in Melbourne Victoria Australia.</Content>
            <Content>Bailey Coffee - crafting traditional artisan coffee - toasted for flavour. </Content>
            <Content> </Content>
            <Content> </Content>
            <Content> </Content>
            <Content> </Content>
            </ContentWrapper>
          </Wrapper>
      </Layout>
    )
  }
}

export default BlogIndex